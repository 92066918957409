<template>
  <div class="modal-overlay d-flex align-items-center">
    <div class="modalWindow" @click.stop>
      <div class="d-flex">
        <h3 class="pt-1 pb-2 text-left text-nowrap w-100">Soustava technologie</h3>
        <div class="text-right cursor-pointer" @click="$emit('close-modal')">
          <img class="closeModal-img" src="@/assets/close-icon.svg" alt="" />
        </div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="numberOfPanels" class="pr-1">Počet panelů</label>
            </div>
            <ValidationProvider :rules="rulesPanels" name="'Počet panelů'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="numberOfPanels"
                type="number"
                min="1"
                max="99"
                size="sm"
                v-model="dataForm.numberOfPanels"
                class="d-table-cell w-100px"
                :title="errors[0]"
              />
              <span class="errMsg d-block pt-1">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="panelType" class="pr-1">Typ panelu</label>
            </div>
            <ValidationProvider :rules="{ required: false, mustBeInList: { list: lists.fvPanelModel }}" name="'Typ panelu'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="panelType"
                list="panelTypeList"
                name="panelType"
                class="form-control form-control-sm d-inline-block w-350px"
                v-model="dataForm.panelType"
                :title="errors[0]"
                :state="validatePanelModel(classes)"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <b-datalist
              id="panelTypeList"
              :options="lists.fvPanelModel"
              autocomplete="off"
            />
            <div class="d-table-cell text-nowrap text-left pl-3">
              {{ panelPowerAndCode(dataForm.panelType) }}
            </div>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="numberOfThongs" class="pr-1 pt-2">Počet stringú</label>
            </div>
            <ValidationProvider :rules="rulesThongs" name="'Počet stringú'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="numberOfThongs"
                type="number"
                min="1"
                max="99"
                size="sm"
                v-model="dataForm.numberOfThongs"
                class="d-table-cell w-100px"
                :title="errors[0]"
              />
              <span class="errMsg d-block pt-1">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="optimizers" class="pr-1">Optimizéry</label>
            </div>
            <ValidationProvider :rules="rulesOptimizers" name="'Optimizéry'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="optimizers"
                type="number"
                min="0"
                max="99"
                size="sm"
                v-model="dataForm.optimizers"
                class="d-table-cell w-100px"
                :title="errors[0]"
              />
              <span class="errMsg d-block pt-1">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="inverterType" class="pr-1">Typ Střídače</label>
            </div>
            <ValidationProvider :rules="{ required: false, mustBeInList: { list: lists.inverterType }}" name="'Typ Střídače'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="inverterType"
                list="inverterTypeList"
                name="inverterType"
                class="form-control form-control-sm d-inline-block w-350px"
                v-model="dataForm.inverterType"
                :title="errors[0]"
                :state="validateInverterType(classes)"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <b-datalist
              id="inverterTypeList"
              :options="lists.inverterType"
              autocomplete="off"
            />
            <div class="d-table-cell text-nowrap text-left pl-3">
              {{inverterCode}}
            </div>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="numPhases" class="pr-1 pt-2">1f/3f</label>
            </div>
            <b-form-select
              id="numPhases"
              v-model="dataForm.numPhases"
              :options="lists.numPhases"
              class="form-select form-select-sm w-100px d-inline-block "
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="batteryCount" class="pr-1 pt-2">Počet baterií</label>
            </div>
            <ValidationProvider :rules="rulesBatteryCount" name="'Počet baterií'" mode="lazy" v-slot="{ classes, errors }">
              <b-form-input
                id="batteryCount"
                type="number"
                min="0"
                max="99"
                size="sm"
                v-model="dataForm.battery.count"
                class="d-table-cell w-100px"
                :title="errors[0]"
              />
              <span class="errMsg d-block pt-1">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="batteryType" class="pr-1">Typ baterie</label>
            </div>
            <b-form-select
              name="batteryType"
              v-model="dataForm.battery.type"
              :options="lists.batteryType"
              class="form-select form-select-sm w-350px d-inline-block"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="accessories" class="pr-1 pt-2">Příslušenství</label>
            </div>
            <b-form-input
              id="accessories"
              type="text"
              size="sm"
              v-model="dataForm.accessories"
              class="d-table-cell w-350px"
            />
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="charger" class="pr-1 pt-2">Nabíječka</label>
            </div>
            <b-form-select
              name="charger"
              v-model="dataForm.charger"
              :options="lists.chargers"
              class="form-select form-select-sm w-350px d-inline-block"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="circuitBreaker" class="pr-1 pt-2">Hl. jistič / jištění nabíječky</label>
            </div>
            <b-form-select
              id="circuitBreaker"
              name="circuitBreaker"
              v-model="dataForm.circuitBreaker"
              :options="lists.circuitBreakers"
              class="form-select form-select-sm w-100px d-inline-block"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </div>

          <div :class="getStyle1">
            <div :class="getStyle2">
              <label for="distributor" class="pr-1 pt-2">Distributor</label>
            </div>
            <b-form-select
              name="distributor"
              v-model="dataForm.distributor"
              :options="lists.distributors"
              class="form-select form-select-sm w-100px d-inline-block"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </div>

          <b-button type="submit" class="mt-3 mb-1" variant="primary">Uložit změny</b-button>

        </b-form>
      </ValidationObserver>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import _ from "lodash";

export default {
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    intNumber: { type: Number },
  },
  computed: {
    inverterCode() {
      const inverter = this.lists.inverters.find(inverter => inverter.model === this.dataForm.inverterType);
      return inverter?.code || '';
    },
    getStyle1() { return 'text-left pt-2 d-table'; },
    getStyle2() { return 'd-table-cell w-180px text-nowrap'; },
    rulesPanels() { return this.isEmpty(this.dataForm.numberOfPanels) ? '' : 'required|integer|min_value:1|max_value:99'; },
    rulesThongs() { return this.isEmpty(this.dataForm.numberOfThongs) ? '' : 'required|integer|min_value:1|max_value:99'; },
    rulesOptimizers() { return this.isEmpty(this.dataForm.optimizers) ? '' : 'required|integer|min_value:0|max_value:99'; },
    rulesBatteryCount() { return this.isEmpty(this.dataForm.battery.count) ? '' : 'required|integer|min_value:0|max_value:99'; },
  },
  data() {
    const oneToNine = Array(10).fill(0).map((e,i)=>i+1);
    return {
      lists: {
        inverterType: [],
        inverters: [],
        batteryCount: oneToNine,
        batteryType: [],
        fvPanels: [],
        fvPanelModel: [],
        numPhases: [],
        chargers: [],
        circuitBreakers: [],
        distributors: [],
      },
      dataForm: {
        numberOfPanels: null,
        panelType: null,
        numberOfThongs: null,
        inverterType: null,
        numPhases: null,
        battery: {
          count: null,
          type: null,
        },
        charger: null,
        accessories: null,
        distributor: null,
        optimizers: null,
      },
    }
  },
  methods: {
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    panelPowerAndCode(panelName) {
      const panel = this.lists.fvPanels.find(panel => panel.model === panelName);
      return panel ? `${panel.power} Wp; ${panel.code}` : '';
    },
    async onSubmit() {
      try {
        const url = `/contract/saveSoustavaTechnologieForm`;
        const {data} = await axios.post(url,
          { data: this.dataForm, id: this.id, kodZakazky: this.kodZakazky, intNumber: this.intNumber }
        );
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('SoustavaTechnologieForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getConstants() {
      try {
        const url = `/common/getConstants`;
        const {data:{dataConst}} = await axios.post(url, { constants:
            [
              'INVERTER_MODELS', 'INVERTERS_FULL', 'BATTERY_CAPACITY', 'BATTERY_TYPE', 'FV_PANELS_FULL', 'FV_PANELS_MODELS',
              'ROOFS', 'NUMBER_PHASES', 'DIRECTIONS', 'CHARGERS', 'CIRCUIT_BREAKERS_2', 'DISTRIBUTORS'
            ]
        });
        this.lists.inverterType = dataConst.INVERTER_MODELS;
        this.lists.inverters = dataConst.INVERTERS_FULL;
        this.lists.batteryType = dataConst.BATTERY_TYPE;
        this.lists.fvPanels = dataConst.FV_PANELS_FULL;
        this.lists.fvPanelModel = dataConst.FV_PANELS_MODELS;
        this.lists.numPhases = dataConst.NUMBER_PHASES;
        this.lists.chargers = dataConst.CHARGERS;
        this.lists.circuitBreakers = dataConst.CIRCUIT_BREAKERS_2;
        this.lists.distributors = dataConst.DISTRIBUTORS;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async getSavedData() {
      const url = `/contract/getSoustavaTechnologie`;
      const { data: {data} } = await axios.get(url, { params: { id: this.id } });
      const soustavaTechnologie = data?.soustavaTechnologie || {};
      this.dataForm = {...this.dataForm, ...soustavaTechnologie};
    },
    isEmpty(str) {
      return (typeof str === "undefined" || str === null || str ===  "");
    },

    // Validations
    validateWithDataFromList(key, isInput, listName) {
      const isEqual = this.lists[listName || key].includes(_.get(this.dataForm, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    validatePanelsFromList(valueForValidation, listName) {
      if (!valueForValidation) return;
      const isEqual = this.lists[listName].includes(valueForValidation);
      return !isEqual;
    },
    validatePanelModel(classes) {
      if (!this.dataForm.panelType) return null;
      return this.getState(classes) && !this.validatePanelsFromList(this.dataForm.panelType, 'fvPanelModel')
    },
    validateInverterType(classes) {
      if (!this.dataForm.inverterType) return null;
      return this.getState(classes) && !this.validatePanelsFromList(this.dataForm.inverterType, 'inverterType')
    },
  },
  async mounted() {
    await this.getConstants();
    await this.getSavedData();
  },

}
</script>

<style scoped>
  .modalWindow {
    width: 750px;
  }
  label {
    padding-right: 10px;
    font-size: 15px;
  }
  .input-group-text {
    max-height: 26px;
    max-width: 15px;
    padding: 6px 2px;
    text-align: center;
    left: 32px;
    position: absolute;
    z-index: 10;
    border: 0;
    background: no-repeat;
  }
  .append {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .app1 > div > div.input-group-text {
    left: 23px;
  }
</style>
