<template>
  <div v-if="$store.getters.isReportInstalaceATechnologiePageAllowed" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line pl-1">
        <tab-buttons :tabName="tabName" :tabs="TABS" class="pb-4 text-left" @click="changeTab" />
      </div>

      <div class="text-left filter-line d-flex">
        <span class="filtry-text">Filtry:</span>
        <b-form-select
            name="filterTypes"
            v-model="filterType"
            :options="listFilterTypes"
            class="form-select form-select-sm d-inline-block w-230px text-black"
            size="sm"
            @change="changeFilterTypes"
        />

        <template v-if="isFilterTypeNazev || isFilterZip || isFilterPhone">
          <b-form-input
              id="searchString"
              type="text"
              size="sm"
              v-model.trim="filter.searchString"
              class="d-inline-block w-250px ml-3 text-black"
              :class="[filter.searchString ? 'input-filtered' : '']"
              placeholder="napište co hledáte..."
              v-debounce="searchByString"
          />
        </template>
        <template v-else-if="isFilterTypeObject">
          <b-form-select
            name="object"
            v-model="filter.object"
            :options="lists.object"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypObjektu"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeKategory">
          <b-form-select
            name="kategory"
            v-model="filter.kategory"
            :options="lists.kategory"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypKategory"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeOkresOP">
          <b-form-input
              list="okresOPList"
              name="okresOP"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.okresOP"
              @change="changeOkresSelect"
          />
          <b-datalist
              id="okresOPList"
              :options="lists.okresy"
              autocomplete="off"
          />
        </template>
        <template v-else-if="isFilterTypeKraj">
          <b-form-select
              name="stav"
              v-model="krajOption"
              :options="KRAJ"
              class="d-inline-block w-250px ml-3 form-select form-select-sm"
              size="sm"
              @change="changeKrajSelect"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeOzUsers">
          <b-form-input
              list="obchodniZastupceList"
              name="obchodniZastupce"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.obchodniZastupceName"
              @change="changeOzUsers"
          />
          <b-datalist
              id="obchodniZastupceList"
              :options="ozUsersNames"
              autocomplete="off"
          />
        </template>
        <template v-else-if="isPManagerKoordinator">
          <b-form-input
              list="managerKoordinatorList"
              name="managerKoordinator"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.managerKoordinatorName"
              @change="changeManagerKoordinator"
          />
          <b-datalist
              id="managerKoordinatorList"
              :options="managerKoordinatorFioTitles"
              autocomplete="off"
          />
        </template>

        <b-icon-x-circle
            @click="clearFilter"
            class="pl-2 pt-1 cursor-pointer"
            :class="[(filter.searchString || filter.obchodniZastupceName || krajOption || filter.managerKoordinatorName) ? 'filtered' : '']"
            font-scale="1.8"
            title="Čištění filtru"
        />
        <span class="pl-5 pt-3px">
          [ zakázek: {{ totalItems }} ]
        </span>
      </div>

      <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          responsive
          hover
          no-border-collapse
          :items="listInstallTechReports"
          :fields="headers"
          class="stickyTable"
          thead-tr-class="align-middle"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-direction="desc"
          no-local-sorting
          @sort-changed="changeOrder"
      >

        <template #cell(orderNumber)="{ item }" class="color1">
          <div class="d-flex justify-content-between text-left">
            <div>
              <b-icon-clipboard-plus
                  class="user-enabled-email cursor-pointer"
                  v-b-tooltip.hover="{
                    title: 'Kopírovat název',
                    delay: { show: 200, hide: 200 },
                    variant: 'secondary'
                  }"
                  @click="copyOP(item)"
              />
              {{ item.orderNumber }}
            </div>
            <a
                :href="`mailto:${item.personaEmail || ''}?subject=${item.orderNumber}`"
                title="Poslat email zákazníkovi"
                class="pl-2"
            >
              <b-icon-envelope-fill class="user-enabled-email"/>
            </a>
          </div>
        </template>

        <template #cell(terminDokonceniRealizace)="{ item }" class="color1">
          <div>
            {{ item.terminDokonceniRealizace }}
          </div>
          <div v-if="!isConnectionDate(item) || (isConnectionDate(item) && !isConnectionDateLessDeadline(item))">
            <span v-if="getDaysLeftCount(item) >= 0">
              (zbývá: {{ getDaysLeftCount(item) }})
            </span>
            <span v-else class="text-red bold">
              (zpoždění: {{ Math.abs(getDaysLeftCount(item)) }})
            </span>
          </div>
        </template>

        <template #cell(dedlineRealizace)="{ item }" class="color1">
          <div>
            {{ getDeadlineDate(item) }}
          </div>
          <div v-if="isConnectionDate(item) && !isConnectionDateLessDeadline(item)">
            <span class="text-red">({{ getFormattedDate(item.connectionDate) }}}</span>
          </div>
        </template>

        <template #head()="{field}">
          <span v-if="field.num" class="rowNumber">{{ field.num }}</span>
          <span v-if="field.isHTML" v-html="field.label"></span>
          <div v-else>{{ field.label }}</div>
        </template>

        <template #cell(obchodniZastupce)="{ item }" class="color1 bg-color-white">
          <div class="line-height-14 fs-11px">
            <template v-if="isTymZakazkyModalAllowed">
              <a
                  href="#"
                  class="pl-2 text-black-color"
                  @click.prevent="openTymZakazky(item)"
                  title="Upravit tým zakázky"
                  v-html="getUsersNamesList(item)"
              ></a>
            </template>
            <template v-else>
              <span v-html="getUsersNamesList(item)"></span>
            </template>
          </div>
        </template>

        <template #cell(date2ZFA)="{ item }" class="color1">
          <div>
            {{ getFormattedDate(item.date2ZFA) || item.date2ZFAParseComment }}
          </div>
        </template>

        <template #cell(installStatus)="{ item }" class="color1">
          <div>
            {{ getFormattedDate(item.installStatus) || item.installStatusParseComment }}
          </div>
        </template>

        <template #cell(inverterInstalled)="{ item }" class="color1">
          <div>
            {{ getFormattedDate(item.inverterInstalled) || item.inverterInstalledParseComment }}
          </div>
        </template>

        <template #cell(batteriesInstalled)="{ item }" class="color1">
          <div>
            {{ getFormattedDate(item.batteriesInstalled) || item.batteriesInstalledParseComment }}
          </div>
        </template>

        <template #cell(podepsanaDate)="{ item }">
          <div class="text-center">
            <b-icon-journal
                font-scale="1.3"
                variant="secondary"
                class="cursor-pointer"
                @click="showKartaModal(item)"
            />
          </div>
          <div class="fs-11px pt-1">
            {{ item.podepsanaDate ? `${getFormattedDate(item.podepsanaDate)}` : ''}}
          </div>
        </template>

      </b-table>

      <div v-if="!listInstallTechReports.length" class="pt-5">
        <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
      </div>

      <soustava-technologie-form
          v-if="showSoustavaTechnologieFormModal && $store.getters.isSoustavaTechnologieModalAllowed"
          :id="selectedContract._id"
          :kodZakazky="selectedContract.kodZakazky"
          :intNumber="selectedContract.intNumber"
          @close-modal="showSoustavaTechnologieFormModal = false"
          @form-saved="formSaved"
      />

      <karta-form
          v-if="showKartaForm"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showKartaForm"
          @close-modal="showKartaForm = false"
      />

      <tym-zakazky-form
          v-if="showTymZakazkyModal && isTymZakazkyModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showTymZakazkyModal"
          @close-modal="closeTymZakazkyForm"
          @save-modal="saveTymZakazkyForm"
      />
    </b-overlay>
  </div>
</template>


<script>

import axios from "axios";
import SoustavaTechnologieForm from "@/components/modals/SoustavaTechnologieForm.vue";
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import {
  FILTER_TYPES,
  LIST_REPORT_INSTALACI_FILTER_TYPES,
  KRAJ, PRIPAD_OBJECTS, PRIPAD_CATEGORIES,
} from "@/constants/form.constants";
import KartaForm from "@/components/modals/KartaForm.vue";
import TabButtons from "@/components/TabButtons.vue";
import {TABULKA_TYPE} from "@/constants/form.constants";
import TymZakazkyForm from "@/components/modals/TymZakazkyForm.vue";
import moment from "moment";

const TABS = [
  {tabName: TABULKA_TYPE.TABULKA_2, link: '/report-instalace-technologie/tabulka-2', name: 'Firmy a veřejnoprávní subjekty', title: 'Firmy a veřejnoprávní subjekty'},
  {tabName: TABULKA_TYPE.TABULKA_1, link: '/report-instalace-technologie/tabulka-1', name: 'Bytové a rodinné domy', title: 'Bytové a rodinné domy'},
];

const defaultSortDirection = true;
const defaultSortBy = 'podepsanaDate';

export default {
  computed: {
    isFilterTypeNazev() {
      return this.filterType === FILTER_TYPES.NAZEV_V_ADRESARE;
    },
    isFilterTypeOzUsers() {
      return [FILTER_TYPES.OZ_USERS_ACTIVE, FILTER_TYPES.OZ_USERS_DEACTIVATED].includes(this.filterType);
    },
    isFilterTypeKraj() {
      return this.filterType === FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU;
    },
    isPManagerKoordinator() {
      return this.filterType === FILTER_TYPES.MANAGER_KOORDINATOR;
    },
    getIdSelectedContract() {
      return this.selectedContract?.contractId || this.selectedContract?._id;
    },
    isFilterTypeOkresOP() {
      return this.filterType === FILTER_TYPES.OKRES_OP;
    },
    isFilterZip() {
      return this.filterType === FILTER_TYPES.ZIP;
    },
    isFilterPhone() {
      return this.filterType === FILTER_TYPES.PHONE;
    },
    isTymZakazkyModalAllowed() {
      return this.$store.getters.isTymZakazkyInstalaceModalAllowed;
    },
    isFilterTypeObject() {
      return this.filterType === FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU;
    },
    isFilterTypeKategory() {
      return this.filterType === FILTER_TYPES.KATEGORIE_PRIPADU;
    },
  },
  mixins: [commonMixin],
  data() {
    // const headVertical1 = 'rotate mw-40px text-nowrap align-bottom pb-4';
    return {
      KRAJ,
      isLoading: false,
      listInstallTechReports: [],
      showModal: false,
      showSoustavaTechnologieFormModal: false,
      showKartaForm: false,
      selectedContract: null,
      showTymZakazkyModal: false,
      sortBy: defaultSortBy,
      sortDesc: true,
      defaultSortBy,
      defaultSortDirection,
      headers: [
        {label: 'Karta zakázky<br><span class="font-weight-normal fs-11px">(datum podpisu smlouvy)</span>', key: 'podepsanaDate', stickyColumn: true, variant: 'color-5', isHTML: true, sortable: true},
        {label: 'Technologie zakázky<br><span class="font-weight-normal fs-11px">(název adresáře v zakázkách)</span>', key: 'orderNumber', num: 1, stickyColumn: true, variant: 'color-5', class: 'mw-400px stickyColumn', isHTML: true, sortable: true},
        {label: 'Tým zakázky<br><span class="font-weight-normal fs-11px">(OZ, PM, PK, PO)</span>', key: 'obchodniZastupce', num: 2, variant: 'color-5', class: 'mw-150px', isHTML: true},
        {label: 'Termín dokončení realizace', key: 'terminDokonceniRealizace', num: 5, variant: 'color-5', class: 'mw-150px', sortable: true},
        {label: 'Deadline', key: 'dedlineRealizace', num: 6, variant: 'color-5', class: 'deadline', sortable: true},
        {label: 'Způsob platby ZF', key: 'paymentMethodZF', num: 7, variant: 'color-5', sortable: true},
        {label: '1.ZFA Datum', key: 'date1ZFA', num: 8, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: '2.ZFA Datum', key: 'date2ZFA', num: 9, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Lze instalovat?', key: 'canBeInstalled', num: 10, variant: 'color-5', class: 'mw-150px', sortable: true},
        {label: 'ZF Temp', key: 'tempZF', num: 11, variant: 'color-5', sortable: true},
        {label: 'Počet panelů', key: 'numberOfPanels', num: 12, variant: 'color-5', sortable: true},
        {label: 'Typ panelu', key: 'panelType', num: 13, variant: 'color-5', class: 'mw-300px', sortable: true},
        {label: 'Registrace záruky Winaico', key: 'warrantyWinaicoReg', num: 14, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Typ střechy / konstrukce', key: 'roofType', num: 15, variant: 'color-5', class: 'mw-200px', sortable: true},
        {label: 'Počet stringů', key: 'numberOfThongs', num: 16, variant: 'color-5', sortable: true},
        {label: 'Optimizéry', key: 'optimizers', num: 17, variant: 'color-5', sortable: true},
        {label: 'Konstrukce na skladě ano/ne', key: 'constructionInStock', num: 18, variant: 'color-5', sortable: true},
        {label: 'Instalace RFVE_DC včetně panelů je komplet', key: 'RFVE_DCInstallComplete', num: 19, variant: 'color-5', class: 'mw-150px', sortable: true},
        {label: 'Kdo instaloval', key: 'whoInstalled', num: 20, variant: 'color-5', sortable: true},
        {label: 'Stav instalace: skladem, instalováno…atd', key: 'installStatus', num: 21, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Typ střídače', key: 'inverterType', num: 22, variant: 'color-5', class: 'mw-300px', sortable: true},
        {label: '1f/3f', key: 'numPhases', num: 23, variant: 'color-5', sortable: true},
        {label: 'Střídač instalován', key: 'inverterInstalled', num: 24, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Počet baterií', key: 'battery.count', num: 25, variant: 'color-5', sortable: true},
        {label: 'Typ baterie', key: 'battery.type', num: 26, variant: 'color-5', class: 'mw-200px', sortable: true},
        {label: 'Baterie instalovány', key: 'batteriesInstalled', num: 27, variant: 'color-5', sortable: true},
        {label: 'Příslušenství', key: 'accessories', num: 28, variant: 'color-5', class: 'mw-300px', sortable: true},
        {label: 'Nabíječka', key: 'charger', num: 29, variant: 'color-5', class: 'mw-200px', sortable: true},
        {label: 'Hl. jistič / jištění nabíječky', key: 'circuitBreaker', num: 30, variant: 'color-5', sortable: true},
        {label: 'Distributor', key: 'distributor', num: 31, variant: 'color-5', sortable: true},
        {label: 'Elektroměr - úprava', key: 'elMeterAdj', num: 32, variant: 'color-5', sortable: true},
        {label: 'Nutnost WIFI opakovače', key: 'isNeedWiFiRepeater', num: 33, variant: 'color-5', sortable: true},
        {label: 'UPS rozvaděč', key: 'UpsSwitchboard', num: 34, variant: 'color-5', sortable: true},
        {label: 'Technická obhlídka', key: 'techInspection', num: 35, variant: 'color-5', sortable: true},
        {label: 'Vícepráce', key: 'moreWork', num: 36, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Datum dokončení (odevzdané předávající protokoly)', key: 'completionDateLogs', num: 37, variant: 'color-5', formatter: cur => this.getFormattedDate(cur), sortable: true},
        {label: 'Poznámky', key: 'comment', num: 38, variant: 'color-5', class: 'mw-150px', sortable: true},
      ],
      filter: {
        name: null,
        searchString: null,
        obchodniZastupceName: null,
        managerKoordinatorName: null,
        managerKoordinatorId: null,
        okresOP: null,
        object: null,
        kategory: null,
        sortBy: null,
        sortDirection: defaultSortDirection,
      },
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      listFilterTypes: this.getFilteredFilterTypes(),
      filterType: FILTER_TYPES.NAZEV_V_ADRESARE,
      ozUsers: [],
      ozUsersNames: [],
      managerKoordinatorFioTitles: [],
      managerKoordinatorFull: [],
      activeOzUsersNames: [],
      activeOzUsersInfo: [],
      deactivatedOzUsersNames: [],
      deactivatedOzUsersInfo: {},
      lists: {
        okresy: [],
        object: PRIPAD_OBJECTS,
        kategory: PRIPAD_CATEGORIES,
      },
      krajOption: null,
      abortController: new AbortController(),
      abortControllerCount: new AbortController(),
      totalItems: 0,
      TABS,
    }
  },
  methods: {
    async getListReportInstalace() {
      try {
        const filterByUserId = this.getSelectedOzUserId();
        if (!filterByUserId && this.filter.obchodniZastupceName) return;

        this.abortController.abort();
        this.abortController = new AbortController();

        this.isLoading = true;
        const url = `/contract/getListReportInstalace`;
        this.filter.managerKoordinatorId = this.getSelectedManagerKoordinatorId();
        const {data: {data}} = await axios.post(url, {
              offset: this.offset,
              limit: this.limit,
              filter: this.filter,
              filterByUserId: this.getSelectedOzUserId(),
              type: this.tabName,
            },
            {signal: this.abortController.signal}
        );
        if (!this.offset) {
          this.listInstallTechReports = data;
        } else {
          this.listInstallTechReports.push(...data);
        }
        this.offset = this.offset > this.listInstallTechReports.length ? this.offset : this.limit + this.offset;
        this.loading = false;
        this.isLoading = false;
      } catch (e) {
        if (!e?.response?.data?.message) {
          // console.error('Operation canceled');
        } else {
          console.error('Get Report Instalace A Technologie info', e.response?.data);
          this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
          this.loading = false;
          this.isLoading = false;
        }
      }
    },
    async getReportInstalaceCount(noCountQuery) {
      try {
        if (noCountQuery) return;

        const filterByUserId = this.getSelectedOzUserId();
        if (!filterByUserId && this.filter.obchodniZastupceName) return;

        this.abortControllerCount.abort();
        this.abortControllerCount = new AbortController();

        const url = `/contract/getReportInstalaceCount`;
        const {data: {data}} = await axios.post(url, {
          filter: this.filter,
          filterByUserId,
          type: this.tabName,
        }, {signal: this.abortControllerCount.signal});
        this.totalItems = data?.total || 0;
      } catch (e) {
        console.error('getReportInstalaceCount', e);
        throw new Error()
      }
    },
    async formSaved() {
      this.offset = 0;
      await this.getInfo();
    },
    async getOzUsersGroupsForUser() {
      try {
        const url = `/user/getOzUsersGroupsForUser`;
        const {data} = await axios.get(url, {});
        this.activeOzUsersNames = data?.data?.activeOzUsersNamesFioTitles || [];
        this.activeOzUsersInfo = data?.data?.activeOzUsersInfo || [];
        this.deactivatedOzUsersNames = data?.data?.deactivatedOzUsersNamesFioTitles || [];
        this.deactivatedOzUsersInfo = data?.data?.deactivatedOzUsersInfo || [];
      } catch (error) {
        console.log(error);
      }
    },
    getDaysLeftCount(item) {
      if (item.connectionDate) {
        const deadline = moment(this.getDeadlineDate(item, true));
        const connectionDate = moment(item.connectionDate);
        return deadline.diff(connectionDate, 'days') - 1;
      }

      const startDate = new Date(item.podepsanaDate);
      const today = new Date();
      return Math.ceil((startDate.getTime() + item.terminDokonceniRealizace * 24 * 60 * 60 * 1000 - today.getTime()) / (1000 * 60 * 60 * 24));
    },
    isConnectionDate(item) {
      return !!item.connectionDate;
    },
    isConnectionDateLessDeadline(item) {
      return moment(item.connectionDate).isBefore(this.getDeadlineDate(item, true));
    },
    getDeadlineDate(item, raw = false) {
      const daysToAdd = parseInt(item.terminDokonceniRealizace, 10);
      const date = new Date(item.podepsanaDate);
      date.setDate(date.getDate() + daysToAdd);
      return raw ? date : this.getFormattedDate(date);
    },
    getUsersNamesList(item) {
      return item.userNamesArray.join('<br>')
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    async searchByString() {
      if (!this.isFilterTypeNazev && !this.isFilterZip && !this.isFilterPhone) return;

      this.offset = 0;
      this.filter.name = this.filterType;
      await this.getInfo();
    },
    async changeTypObjektu(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU);
    },
    async changeTypKategory(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KATEGORIE_PRIPADU);
    },
    async changeOkresSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OKRES_OP);
    },
    async clearFilter() {
      this.offset = 0;
      this.filter.name = this.filterType;
      this.filter.searchString = null;
      this.filter.obchodniZastupceName = null;
      this.filter.managerKoordinatorName = null;
      this.filter.okresOP = null;
      this.filter.object = null;
      this.filter.kategory = null;
      this.krajOption = null;
      this.resetScrollTop();
      await this.getInfo();
    },
    getFilteredFilterTypes() {
      return this.getFilteredByOzUsersFilterTypes(LIST_REPORT_INSTALACI_FILTER_TYPES)
    },
    async changeTab() {
      this.offset = 0;
      await this.getInfo();
    },
    async changeFilterTypes() {
      switch (this.filterType) {
        case FILTER_TYPES.OZ_USERS_ACTIVE:
          this.ozUsers = this.activeOzUsersInfo;
          this.ozUsersNames = this.activeOzUsersNames;
          break;
        case FILTER_TYPES.OZ_USERS_DEACTIVATED:
          this.ozUsers = this.deactivatedOzUsersInfo;
          this.ozUsersNames = this.deactivatedOzUsersNames;
          break;
      }
      await this.clearFilter();
    },
    getSelectedOzUserId() {
      return _.first(this.ozUsers.filter(u => u.fullNameFioTitles === this.filter.obchodniZastupceName))?.id;
    },
    changeOzUsers() {
      this.resetScrollTop();
    },
    changeManagerKoordinator() {
      this.resetScrollTop();
    },
    async changeSelectList(searchString, filterName) {
      this.offset = 0;
      this.filter.name = filterName;
      this.filter.searchString = searchString;
      this.resetScrollTop();
      await this.getInfo();
    },
    async changeKrajSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU);
    },
    async getManagersKoordinators() {
      const url = `/user/getManagersKoordinators`;
      const {data} = await axios.get(url, {});
      this.managerKoordinatorFioTitles = data?.data?.managersKoordinatorsNamesFioTitles || [];
      this.managerKoordinatorFull = data?.data?.managersKoordinatorsInfo || [];
    },
    getSelectedManagerKoordinatorId() {
      return _.first(this.managerKoordinatorFull.filter(u => u.fullNameFioTitles === this.filter.managerKoordinatorName))?.id;
    },
    showKartaModal(item) {
      this.selectedContract = item;
      this.showKartaForm = true;
    },
    async getOkresy() {
      try {
        this.lists.okresy = await this.getOkresyList();
      } catch (error) {
        console.log(error);
      }
    },
    async getInfo(noCountQuery = false) {
      await Promise.all([
        this.getListReportInstalace(noCountQuery),
        this.getReportInstalaceCount(noCountQuery),
      ]);
    },
    openTymZakazky(item) {
      this.selectedContract = item;
      this.showTymZakazkyModal = true;
    },
    closeTymZakazkyForm() {
      this.showTymZakazkyModal = false;
    },
    async saveTymZakazkyForm() {
      this.closeTymZakazkyForm();
      await this.formSaved();
    },
    copyOP(item) {
      this.copyToClipboard(item.orderNumber);
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$snotify['success']('Text byl zkopírován do schránky');
    },
    setDefaultSort() {
      this.sortBy = this.defaultSortBy;
      this.sortDesc = this.defaultSortDirection;
      this.filter.sortBy = this.defaultSortBy;
      this.filter.sortDirection = this.defaultSortDirection;
    },
    async changeOrder(ctx) {
      if (ctx.sortBy) {
        this.filter.sortBy = ctx.sortBy;
        this.filter.sortDirection = ctx.sortDesc;
      } else {
        this.setDefaultSort();
      }
      this.offset = 0;
      this.resetScrollTop();
      await this.getInfo(true);
    },
  },
  props: {
    tabName: {type: String, required: true},
  },
  components: {TymZakazkyForm, KartaForm, SoustavaTechnologieForm, TabButtons},
  async mounted() {
    this.isLoading = true;
    this.filter.name = FILTER_TYPES.NAZEV_V_ADRESARE;
    await this.getManagersKoordinators();

    await Promise.all([
      this.getInfo(),
      this.getOzUsersGroupsForUser(),
      this.getOkresy(),
    ]);

    const table = this.$el?.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style scoped>
.rotate > div {
  transform: rotate(-90deg);
}
.deadline > div {
  font-weight: normal;
  font-size: 13px;
}
.table-bordered th, .table-bordered td {
  border: 1px solid black !important;
}

</style>
